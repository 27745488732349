<template>
  <div>
    <b-col
      cols="12"
      xl="12"
      lg="12"
      md="12"
    >
      <b-card>
        <b-row>
          <!-- Right Col: Table -->
          <!-- <b-col cols="6" xl="6">
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon icon="UserIcon" class="mr-75" />
                  <span class="font-weight-bold">ISP Name</span>
                </th>
                <td class="pb-50">{{  }}</td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="CheckIcon" class="mr-75" />
                  <span class="font-weight-bold">Bank Transaction ID</span>
                </th>
                <td class="pb-50 text-capitalize">{{   }}</td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="StarIcon" class="mr-75" />
                  <span class="font-weight-bold">Remark</span>
                </th>
                <td class="pb-50 text-capitalize">{{  }}</td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="FlagIcon" class="mr-75" />
                  <span class="font-weight-bold">Status</span>
                </th>
                <td class="pb-50">{{  }}</td>
              </tr>
            </table>
          </b-col> -->
          <b-col
            cols="6"
            xl="6"
          >
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <b-avatar
                    variant="light-success"
                    rounded
                  >
                    <feather-icon
                      icon="DollarSignIcon"
                      size="18"
                    />
                  </b-avatar>
                  <span class="font-weight-bold">Total Transection Amount</span>
                </th>
                <td class="pb-50 text-capitalize font-weight-bolder text-success">
                  {{ summery.total_invoice_amount }} BDT
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <b-avatar
                    variant="light-success"
                    rounded
                  >
                    <feather-icon
                      icon="DollarSignIcon"
                      size="18"
                    />
                  </b-avatar>
                  <span class="font-weight-bold">Processing Free</span>
                </th>
                <td class="pb-50 text-capitalize font-weight-bolder text-success">
                  {{ summery.total_processing_free }} BDT
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <b-avatar
                    variant="light-success"
                    rounded
                  >
                    <feather-icon
                      icon="DollarSignIcon"
                      size="18"
                    />
                  </b-avatar>
                  <span class="font-weight-bold">Total Receivable Amount</span>
                </th>
                <td class="pb-50 text-capitalize font-weight-bolder text-success">
                  {{ summery.receivable_amount }} BDT
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-card-code>
      <h3>Un-Settlement Details List ({{ unSettlementList.data.length }})</h3>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="unSettlementList.data"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        theme="my-theme"
      >
        <!-- Column: Actions -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <div v-if="props.column.field === 'paid_date'">
            {{ props.row.paid_date }}
          </div>
          <span
            v-if="props.column.field == 'invoice_code'"
            class="text-nowrap"
          >
            <b-link
              :to="{ name: 'isp-invoice-preview', params: { invoiceId: props.row.id }}"
              class="font-weight-bold"
            >
              {{ props.row.invoice_code }}
            </b-link>
          </span>
          <span
            v-if="props.column.field == 'user'"
            class="text-nowrap"
          >
            {{ props.row.isp_user_name ? props.row.isp_user_name : null }}
          </span>

          <div v-if="props.column.field === 'pending_amount'">
            {{ props.row.pending_amount }}
          </div>
          <div v-if="props.column.field === 'charge_amount'">
            {{ props.row.charge_amount }}
          </div>
          <div v-if="props.column.field === 'total_payable_amount'">
            {{ props.row.total_payable_amount }}
          </div>
        </template>

      </vue-good-table>
      <pagination
        class="mt-3 float-right"
        :data="unSettlementList"
        @pagination-change-page="getItems"
      />
    </b-card-code>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BLink,
  BAvatar,
  VBToggle,
  BCard,
} from 'bootstrap-vue'
import { ISP_LIST, UN_SETTLEMENT_LIST } from '@core/services/api'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
// Use toast
import Mixin from '@core/services/mixin'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BLink,
    BCard,
  },
  mixins: [Mixin],
  data() {
    return {
      avatarText,
      toggle: false,
      isShow: false,
      pageLength: 100,
      dir: false,
      required,
      columns: [
        { field: 'paid_date', label: 'Transaction Date', sortable: true },
        { field: 'user', label: 'User Name', sortable: true },
        { field: 'invoice_code', label: 'Invoice Code', sortable: true },
        {
          field: 'pending_amount',
          label: 'Transaction Amount',
          // sortable: true,
          formatter: val => `${val} BDT`,
        },
        {
          field: 'charge_amount',
          label: 'Processing Fees',
          // sortable: true,
          formatter: val => `${val} BDT`,
        }, {
          field: 'total_payable_amount',
          label: 'Credit Amount (Receivable)',
          // sortable: true,
          formatter: val => `${val} BDT`,
        },
      ],
      unSettlementList: { data: [] },
      invoice_ids: [],
      isp_list: [],
      isp_id: '',
      searchTerm: '',
      from_date: '',
      to_date: '',
      bank_trans_id: null,
      remakr: null,
      summery: '',
      settlementInfo: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getItems()
  },
  mounted() {
    this.idpListData()
  },
  methods: {
    getItems(page = 1) {
      this.$http
        .get(process.env.VUE_APP_BASEURL + UN_SETTLEMENT_LIST, {
          params: { page },
        })
        .then(res => {
          this.unSettlementList = res.data.data.unSettlementList
          this.summery = res.data.data.summery
        })
    },
    idpListData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + ISP_LIST)
        .then(res => {
          this.isp_list = res.data.data
        })
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
